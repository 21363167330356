import React from "react"

import NikeYearInReview from "/components/Holiday2020/NikeYearInReview"
import SEO from "/components/seo"

const NikeGlobalYearHoliday2020 = () => (
  <>
    <SEO
      title="Thesis | Holiday 2020: Nike Global (Year in Review)"
      description="Happy holidays from Thesis, a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <NikeYearInReview />
  </>
)

export default NikeGlobalYearHoliday2020
